
import React from 'react';
// @ts-ignore
import pic1 from '../assets/images/pic02.jpg';
// @ts-ignore
import pic2 from '../assets/images/apps.jpg';
// @ts-ignore
import pic3 from '../assets/images/cloud.jpg';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>...SERVICES...</h2>
        <em>“The secret of change is to focus all your energy not on fighting the old but on building the new.”</em>
      </header>

    </article>
    <section className="wrapper style5">
      <div className="cardInner">

        <div className="card block-details">
          <img src={pic1} alt="Development" />
          <div className="card-body">
            <h6>IT Support</h6>
            <p>Our IT training helps you solve real-world practical computing issues and practice for
              certification exams. </p>
          </div>
        </div>
        <div className="card block-details">
          <img src={pic2} alt="Software" />
          <div className="card-body">
            <h6> Software </h6>
            <p>Software technology A general term covering the development methods,programming languages,
              and tools to support them that may be used in the development of software. </p>
          </div>
        </div>
        <div className="card block-details">
          <img src={pic3} alt="Cloud" />
          <div className="card-body">
            <h6> Cloud </h6>
            <p>"Everything is going to be connected to cloud and data...All of this will be mediated
              by software"</p>
          </div>

        </div>
      </div>
    </section>
    <section className="wrapper style5">
      <div className="inner">
        <h3>VMware</h3>
        <strong>VMware on AWS</strong> || <strong>End Point Security</strong> || <strong>HCX, SDDC, NSX Cloud</strong>
        <br />
        <strong>"Allows businesses to run multiple application and operating system workloads on the one server"</strong>
        <hr />

        <h5>VMware on AWS</h5>
        <p>
          VMware Cloud on AWS brings VMware’s enterprise-class Software-Defined Data Center software to the AWS Cloud, and enables customers to run production applications across
          VMware vSphere®-based private, public, and hybrid cloud environments, with optimized access to AWS services.
        </p>
        <h5>End Point Security</h5>
        <p>
          VMware Cloud on AWS brings VMware’s enterprise-class Software-Defined Data Center software to the AWS Cloud, and enables customers to run production applications across
          VMware vSphere®-based private, public, and hybrid cloud environments, with optimized access to AWS services.
        </p>
        <h5>HCX, SDDC, NSX Cloud</h5>
        <ul>
          <li> <b>HCX (Hybrid Cloud Extension):</b> VMware HCX streamlines application migration, workload rebalancing and
          business continuity across data centers and clouds.
          </li>
          <li><b>SDDC (Software Defined Data Center):</b> The software-defined data center (SDDC) architecture enables a
          fully automated, zero-downtime infrastructure for any application, and any hardware, now and in the future.
          </li>
          <li>
          <b> NSX Cloud:</b> Deliver consistent networking and security for your applications running natively in public clouds with NSX Cloud.
        </li>
        </ul>
        <hr />
        <h3>MANAGED IT SERVICE</h3>
        <p>
          IT services refers to the application of business and technical expertise to enable organizations
          in the creation, management and optimization or access to information and business processes.
        </p>
        <hr />
        <h3>TECHNOLOGIES</h3>
        <p>
          Software technology A general term covering the development methods, programming languages,
          and tools to support them that may be used in the development of software.
        </p>
        <hr />
        <h3>IT-INFRASTRUCTURE</h3>
        <p>
          JR GLOBAL IT team delivers a full set of services to keep your IT infrastructure reliable with on-going infrastructure administration, continuous monitoring and prompt troubleshooting. We are also ready to plan, implement
          and manage changes in your IT environment to support the uninterrupted flow of your business operations.
        </p>
        <hr />
        <h3>IT SUPPORT</h3>
        <p>
          IT support comprises procedures intended to maintain failsafe IT workflows and reduce IT costs. JR Globals's IT support services include IT help desk, Network Operations Center, Security Operation System and software
          support to guarantee superior user experience and on-the-go improvement of your IT processes.  </p>
        <hr />
        <h3>PROFESSIONAL TRAINING</h3>
        <p>
          Our IT training helps you solve real-world practical computing issues and practice for certification exams. Dive into troubleshooting Windows, Linux, and Mac OS X; set up networks, servers, and client services;
          manage big data; and keep your organization's network secure.
        </p>
        <hr />

      </div>

    </section>
    <section>
      <div className="align-center">
        <header className="major">
          <h2>SOLUTIONS</h2>
        </header>
        <p>Customer Centricity and Agility for Services.
          While challenges and opportunities continue to present themselves, the key to success is to dynamically manage inputs and deliver consistent cost-effective outputs to customers.</p>
        <span><b>Cyber Security|</b></span> <span><b>Data Analytics|</b></span> <span><b>Internet Of Things|</b></span> <span><b>App  Development</b></span>
        <br />
        <button type="button">Get Started</button>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
